<template>
  <v-container>
    <v-row class="title-sticky">
      <v-col cols="12" lg="12">
        <TitleSection :title="title[lang]"></TitleSection>
      </v-col>
    </v-row>

    <v-row v-if="loading">
      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="12"
        v-for="(item, idx) in 6"
        :key="item + 8974100 + idx"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row
      :class="sections.length > 3 ? 'd-flex justify-content-center' : ''"
      v-else
    >
      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="12"
        v-for="(item, idx) in sections"
        :key="item.IDCOLECCION + 897456 + idx"
        class="col-card-about"
      >
        <CardAbout
          class="readable"
          :section="item"
          :href="{
            name: 'SectionPage',
            params: { section: getPinCollection(item.IDCOLECCION) },
          }"
        ></CardAbout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CardAbout from "./components/CardAbout.vue";
import TitleSection from "@/components/titles/TitleSection";
export default {
  components: {
    CardAbout,
    TitleSection,
  },
  data() {
    return {
      loading: true,
      title: {
        en: "About us",
        es: "Conócenos",
      },
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      sections: "getAboutSections",
      accTools: "getAccesibilityTool",
    }),
  },
  watch: {
    lang(olvVal) {
      this.loadData();
    },
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.$store.commit("activeLoader", true);
      await this.$store.dispatch("fetchAboutUsSections");
      this.$store.commit("activeLoader", false);
      this.loading = false;
      this.accTools.restart(this.lang)
    },
    getPinCollection(collectionId) {
      if (collectionId == "-1") return "mapa";
      if (collectionId == "0") return "actividades";
      return collectionId;
    },
  },
};
</script>

<style>
</style>